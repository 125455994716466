import React, { useState } from "react";
import {Button, Grid, TextField, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import useMfa from "../../auth/useMfa";
import {LoadingButton} from "@mui/lab";
import {ToastTypes, useToast} from "./memberToast";
import {MfaTypes} from "../../auth/authClient";

const contactMethods = {
    [MfaTypes.email]: "email address.",
    [MfaTypes.sms]: "mobile phone number.",
    [MfaTypes.totp]: "authenticator app."
}


const MfaChallenge = (props = false) => {
    const [code, setCode] = useState("")
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const {validateMfa, mfaState, disableMfa} = useMfa()
    const {showToast} = useToast()

    const authText = contactMethods[mfaState.type] || "authentication medium."

    const navigateAway = () => {
        if (props.navigateTo){
            navigate(props.navigateTo)
        } else {
            navigate('/')
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            if (props.disable) {
                await disableMfa(code, true)
            } else {
                await validateMfa(code, mfaState.userId)
            }
            if (props.messageOnSuccess){
                showToast(props.messageOnSuccess, ToastTypes.WHITE_SUCCESS)
            }
            navigateAway()
        } catch {
            setError("Invalid code")
        } finally {
            setLoading(false)
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="caption">
                        {`Enter your multi-factor authentication code here. Check your registered ${authText}`}
                    </Typography>
                    <TextField
                        margin="dense"
                        value={code}
                        error={!!error}
                        helperText={error}
                        onChange={(e) => setCode(e.target.value)}
                        onFocus={() => setError(null)}
                        fullWidth
                        id="code"
                        name="code"
                        type="text"
                    />
                </Grid>
            </Grid>
            <Grid container flexDirection="row" mt={1} spacing={2} justifyContent="center">
                <Grid item>
                    <Button variant="outlined" size={"large"} onClick={navigateAway}>
                        Cancel
                    </Button>
                </Grid>
                <Grid item>
                    <LoadingButton variant="contained" size={"large"} type="submit" loading={loading}>
                        {props.disable ? "Disable" : "Verify"}
                    </LoadingButton>
                </Grid>
            </Grid>
        </form>
    )
}

export default MfaChallenge