import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PasswordIcon from "@mui/icons-material/Password";

export const noop = () => void 0;

export const staticLinkFinancialTips = "https://www.onedigital.com/financial-academy";
export const statickLinkCalendly = "https://calendly.com/d/d5w-6gj-fx9";

export const ROUTE_NAMES = {
    WELCOME: "WELCOME",
    SECURITY: "SECURITY",
    SETTINGS: "SETTINGS",
    USER_PROFILE: "USER_PROFILE",
    ONBOARDING: "ONBOARDING",
    LOGIN: "LOGIN",
    EXTRA: "EXTRA",
    RECOVER_PASSWORD: "RECOVER_PASSWORD",
    RESET_PASSWORD: "RESET_PASSWORD",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    MFA_SETUP: "MFA_SETUP"
};

export const ROUTES = {
    [ROUTE_NAMES.WELCOME]: "/",
    [ROUTE_NAMES.SETTINGS]: "/settings",
    [ROUTE_NAMES.SECURITY]: "/settings/security",
    [ROUTE_NAMES.USER_PROFILE]: "/settings/user-profile",
    [ROUTE_NAMES.ONBOARDING]: "/onboarding",
    [ROUTE_NAMES.EXTRA]: "/extra",
    [ROUTE_NAMES.LOGIN]: "/login",
    [ROUTE_NAMES.RECOVER_PASSWORD]: "/recover-password",
    [ROUTE_NAMES.RESET_PASSWORD]: "/reset-password",
    [ROUTE_NAMES.CHANGE_PASSWORD]: "/settings/change-password",
    [ROUTE_NAMES.MFA_SETUP]: "/settings/mfa-setup"
};

export const ROUTES_PATH = {
    "/": ROUTE_NAMES.WELCOME,
    "/settings": ROUTE_NAMES.SETTINGS,
    "/settings/security": ROUTE_NAMES.SECURITY,
    "/settings/user-profile": ROUTE_NAMES.USER_PROFILE,
    "/settings/change-password": ROUTE_NAMES.CHANGE_PASSWORD,
    "/onboarding": ROUTE_NAMES.ONBOARDING,
    "/login": ROUTE_NAMES.LOGIN,
    "/extra": ROUTE_NAMES.EXTRA,
    "/recover-password": ROUTE_NAMES.RECOVER_PASSWORD,
    "/reset-password": ROUTE_NAMES.RESET_PASSWORD,
    "/settings/mfa-setup": ROUTE_NAMES.MFA_SETUP
};

export const settingsMenu = [
    {label: "User Profile", icon: <AccountCircleOutlinedIcon/>, path: ROUTES[ROUTE_NAMES.USER_PROFILE]},
    {label: "Sign-in and Security", icon: <PasswordIcon/>, path: ROUTES[ROUTE_NAMES.SECURITY]},
];

export const environments = {
    PROD: "production",
    DEV: "development",
};

export const PAGE_TAG_NAMES = {
    [ROUTE_NAMES.WELCOME]: "MyOneDigital | Power Your Financial Potential",
    [ROUTE_NAMES.ONBOARDING]: "Create Your MyOneDigital Account",
    [ROUTE_NAMES.USER_PROFILE]: "My Profile | MyOneDigital",
    [ROUTE_NAMES.SECURITY]: "Security Settings | MyOneDigital",
    [ROUTE_NAMES.CHANGE_PASSWORD]: "Change Password | MyOneDigital",
    [ROUTE_NAMES.EXTRA]: "Extra to test",
    [ROUTE_NAMES.LOGIN]: "Login | MyOneDigital",
    [ROUTE_NAMES.RECOVER_PASSWORD]: "Recover Password | MyOneDigital",
    [ROUTE_NAMES.RESET_PASSWORD]: "Reset Password | MyOneDigital",
    [ROUTE_NAMES.MFA_SETUP]: "Mfa Setup | MyOneDigital",
};
