import {Box, Card, CardContent, CircularProgress, Divider, Typography, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import Row from "@components/row";
import useAuth from "@auth/useAuth";
import useApi from "@auth/useApi";

const UserProfile = () => {
    const axios = useApi();
    const {loggedIn} = useAuth();
    const theme = useTheme();
    const [userCards, setUserCards] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (loggedIn) {
                const resp = await axios.post("/prepareUserProfile");
                const {firstName, lastName, businessEmail, personalEmail, phone} = resp.data;

                let userCards = [];

                if (firstName !== undefined && lastName !== undefined) {
                    userCards.push({
                        title: "Basic information",
                        rows: [
                            [
                                {text: "Name", mdSize: 3},
                                {text: firstName + " " + lastName, mdSize: 9},
                            ],
                        ],
                    });
                }

                let informationRows = [];
                if (businessEmail) {
                    informationRows.push([
                        {text: "Work Email Address", mdSize: 3},
                        {text: businessEmail, mdSize: 9},
                    ]);
                }
                if (personalEmail) {
                    informationRows.push([
                        {text: "Personal Email Address", mdSize: 3},
                        {text: personalEmail, mdSize: 9},
                    ]);
                }
                if (phone) {
                    informationRows.push([
                        {text: "Mobile Phone Number", mdSize: 3},
                        {text: phone, mdSize: 9},
                    ]);
                }

                userCards.push({
                    title: "Contact Information",
                    rows: informationRows,
                });

                setUserCards(userCards);
            }
        };
        fetchData();
    }, [loggedIn, axios]);

    return (
        <Box textAlign="left">
            <Typography variant="h4" fontWeight="bold">
                User Profile
            </Typography>
            <Typography variant="h6" color={theme.palette["auxiliar"]}>
                Information about you and your preferences
            </Typography>
            {userCards.length > 0 ? userCards.map((card, indexCard) => (
                <Card className="card-user-profile" key={indexCard}>
                    <CardContent>
                        <Typography variant="h5" fontWeight="bold" mb={1}>
                            {card.title}
                        </Typography>
                        {card.rows.map((cols, indexCol) => (
                            <div key={`${indexCard}-${indexCol}`}>
                                <Row cols={cols}/>
                                {card.rows.length - 1 !== indexCol && <Divider/>}
                            </div>
                        ))}
                    </CardContent>
                </Card>
            )) : <Box minHeight={"40vh"}
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      justifyContent={"center"}>
                <CircularProgress/>
            </Box>}
        </Box>
    );
};

export default UserProfile;
