import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    Grid,
    Typography,
    TextField,
    Button,
    InputAdornment,
    IconButton,
    Box,
    InputLabel,
    useTheme,
    CircularProgress,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import useApi from "@auth/useApi";
import Snackbar from "@mui/material/Snackbar";
import SuccessMessage from "@components/successMessage";
import LayoutForm from "@components/layoutForm";
import TextInfo from "@components/textInfo";
import { resolveErrorMessage } from "@utils/utils";
import MuiAlert from "@mui/material/Alert";
import {
    errorMessages,
    initAlert,
    initLoading,
    initRules,
    initRulesOther,
    initUser,
    ONBOARDING_FIELDS,
    passwordFields,
    statesRules,
    URL_ADV,
    URL_POLICY,
    URL_TyC,
    regexLowerCase,
    regexUpperCase,
    regexNumber,
    regexNames,
    regexEmail,
} from "./constants";
import { Rules } from "./components";
import { BodyBox, BodyDiv, CustomCheckBox, CustomInputPhone, ImgBox, Layout } from "./styles";

const { INVITE_CODE, FIRST_NAME, LAST_NAME, SECONDARY_EMAIL, PRIMARY_EMAIL, PHONE, PASSWORD, CONFIRM_PASSWORD } =
    ONBOARDING_FIELDS;

const Onboarding = () => {
    const [rules, setRules] = useState(initRules);
    const [rulesOther, setRulesOther] = useState(initRulesOther);
    const [isPswFocus, setIsPswFocus] = useState(passwordFields.nonePsw);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(initLoading);
    const [loadingInviteCode, setLoadingInviteCode] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alert, setAlert] = useState(initAlert);
    const [user, setUser] = useState(initUser);
    const [errors, setErrors] = useState({});
    const theme = useTheme();
    const api = useApi();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const hasInviteCode = !!token;
    const [isIndividualCode, setIsIndividualCode] = useState(false);
    const [isInviteCodeOk, setIsInviteCodeOk] = useState(true);
    const [canContinue, setCanContinue] = useState(hasInviteCode || false);
    const [inviteCode, setInviteCode] = useState(() => (hasInviteCode ? token : ""));
    const [success, setSuccess] = useState(false);
    const [isUnderstanding, setIsUnderstanding] = useState(false);
    const [isWithoutSecondaryEmail, setIsWithoutSecondaryEmail] = useState(false);
    const navigate = useNavigate();

    const validations = {
        [INVITE_CODE]: (value) => !hasInviteCode && !value,
        [FIRST_NAME]: (value) => !value || !regexNames.test(value.trim()),
        [LAST_NAME]: (value) => !value || !regexNames.test(value.trim()),
        [SECONDARY_EMAIL]: (value) => !value || !regexEmail.test(value),
        [PRIMARY_EMAIL]: (value) => !value || !regexEmail.test(value),
        [PHONE]: (value) => !value || value.length !== 10,
        [PASSWORD]: (value) => !value || !rules.every((rule) => rule.state.name === statesRules.done.name),
        [CONFIRM_PASSWORD]: (value) => !value || value !== user.password,
    };

    const handleErrorField = (field, hasError) => {
        if (hasError) {
            setErrors((prev) => ({ ...prev, [field]: errorMessages[field] }));
        }
        if (!hasError && errors[field]) {
            setErrors((prev) => {
                const copyErrors = { ...prev };
                delete copyErrors[field];
                return copyErrors;
            });
        }
    };

    const fetchInviteCode = useCallback(
        async (_inviteCode) => {
            const { data } = await api.post(
                "/validateInviteCode",
                { inviteCode: _inviteCode },
                { headers: { hideErrorNotification: true } }
            );
            if (data.inviteCodeType === "INDIVIDUAL") {
                setUser((prev) => ({ ...prev, [PRIMARY_EMAIL]: data?.userInfo[PRIMARY_EMAIL] }));
                setIsIndividualCode(true);
            }
        },
        [api]
    );

    useEffect(() => {
        const retrieveUserInfo = async () => {
            try {
                await fetchInviteCode(token);
                setIsInviteCodeOk(true);
            } catch (error) {
                setIsInviteCodeOk(false);
                setErrors((prev) => ({ ...prev, [INVITE_CODE]: resolveErrorMessage(error, "Invalid invite code") }));
            }
        };

        if (hasInviteCode) {
            retrieveUserInfo();
        }
    }, [fetchInviteCode, hasInviteCode, token]);

    function logInPopup() {
        navigate("/login");
    }

    useEffect(() => {
        const getStatesRules = (isTrue) => (isTrue ? statesRules.done : statesRules.wrong);

        const validatePsw = (val) => {
            const objRules = {
                0: getStatesRules(regexLowerCase.test(val)),
                1: getStatesRules(regexUpperCase.test(val)),
                2: getStatesRules(regexNumber.test(val)),
                3: getStatesRules(val.length >= 8),
            };
            setRules((prev) => prev.map((rule, index) => ({ ...rule, state: objRules[index] })));
        };

        const validatePswOther = (val) => {
            setRulesOther((prev) => prev.map((rule) => ({ ...rule, state: getStatesRules(user.password === val) })));
        };

        validatePsw(user.password);
        validatePswOther(user.confirmPassword);
    }, [user.password, user.confirmPassword]);

    const handleBlur = ({ target }) => {
        const field = target.name;
        const value = field === INVITE_CODE ? inviteCode : user[field];
        const hasError = validations[field](value);
        handleErrorField(field, hasError);
    };

    const handleBlurInviteCode = ({ target }) => {
        if (inviteCode) {
            handleBlur({ target })
        }
    }

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setUser({ ...user, [name]: value });
    };

    const handleChangeInviteCode = ({ target }) => {
        setInviteCode(target.value);
    };

    const handleCheck = (e) => {
        setIsUnderstanding(e.target.checked);
    };

    const handleSecondaryEmailCheck = (e) => {
        const isChecked = e.target.checked;
        setIsWithoutSecondaryEmail(isChecked);
        if (isChecked) {
            setUser({ ...user, [SECONDARY_EMAIL]: "" });
            handleErrorField(SECONDARY_EMAIL, false);
        } else {
            handleBlur({ target: { name: SECONDARY_EMAIL } });
        }
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenAlert(false);
        setAlert(initAlert);
    };

    const onSubmitInviteCode = async (e) => {
        e.preventDefault();
        setLoadingInviteCode(true);
        try {
            await fetchInviteCode(inviteCode);
            setIsInviteCodeOk(true);
            setCanContinue(true);
        } catch (error) {
            setIsInviteCodeOk(false);
            setErrors((prev) => ({ ...prev, [INVITE_CODE]: resolveErrorMessage(error, "Invalid invite code") }));
        } finally {
            setLoadingInviteCode(false);
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if (loading.show) return;
        setLoading({ show: true, msg: "Adding user" });

        let isValid = true;
        //needs extra validation because can submit while changing some field
        //before onBlur triggers

        Object.keys(user).forEach((keyUser) => {
            if (isValid && keyUser !== SECONDARY_EMAIL) {
                const value = user[keyUser];
                const hasError = validations[keyUser](value);
                handleErrorField(keyUser, hasError);
                isValid = !hasError;
            }
        });

        const secondaryEmailHasError = validations[SECONDARY_EMAIL](user[SECONDARY_EMAIL]);
        const isSecondaryEmailOk = isWithoutSecondaryEmail || !secondaryEmailHasError;

        if (!isInviteCodeOk || !isUnderstanding || !isSecondaryEmailOk) {
            isValid = false;
        }

        if (isValid) {
            const objRequest = {
                inviteCode: inviteCode,
                ...user,
            };
            setErrors({});
            try {
                await api.post(`/register`, objRequest);
                setLoading(initLoading);
                setAlert(initAlert);
                setIsUnderstanding(false);
                setSuccess(true);
            } catch (error) {
                setLoading(initLoading);
            }
        } else {
            setLoading(initLoading);
            setAlert({ type: "error", msg: "Complete/Check your information" });
            setOpenAlert(true);
        }
    };

    const getPswTypeField = (show) => (show ? "text" : "password");

    if (loading.show) {
        return (
            <Box
                textAlign="center"
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="1rem"
                height="100vh"
            >
                <CircularProgress />
                <Typography variant="p" fontSize={20}>
                    {loading.msg}
                </Typography>
            </Box>
        );
    }

    const renderInviteCodeForm = () => (
        <form onSubmit={onSubmitInviteCode}>
            <Grid container mt={3} spacing={2}>
                <Grid item xs={12}>
                    <InputLabel htmlFor={INVITE_CODE}>Invite code *</InputLabel>
                    <TextField
                        margin="dense"
                        error={!!errors[INVITE_CODE]}
                        helperText={errors[INVITE_CODE]}
                        fullWidth
                        id={INVITE_CODE}
                        name={INVITE_CODE}
                        value={inviteCode}
                        onChange={handleChangeInviteCode}
                        autoFocus
                        onBlur={handleBlurInviteCode}
                        onFocus={() => handleErrorField(INVITE_CODE, false)}
                    />
                </Grid>
            </Grid>
            <Grid container mt={3} spacing={2}>
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        size="large"
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={!inviteCode || loadingInviteCode}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </form>
    );

    const renderDirectForm = () => (
        <form onSubmit={onSubmit}>
            <Grid container mt={3} spacing={2}>
                {hasInviteCode && (
                    <Grid item xs={12}>
                        <InputLabel htmlFor={INVITE_CODE}>Invite code *</InputLabel>
                        <TextField
                            margin="dense"
                            error={!!errors.inviteCode}
                            helperText={errors.inviteCode}
                            fullWidth
                            id={INVITE_CODE}
                            name={INVITE_CODE}
                            disabled={hasInviteCode}
                            value={inviteCode}
                            onChange={handleChangeInviteCode}
                            autoFocus={!hasInviteCode}
                            onBlur={handleBlur}
                            onFocus={() => handleErrorField(INVITE_CODE, false)}
                        />
                    </Grid>
                )}
                <Grid item xs={12} md={6}>
                    <InputLabel htmlFor={FIRST_NAME}>First Name *</InputLabel>
                    <TextField
                        margin="dense"
                        value={user[FIRST_NAME]}
                        error={!!errors[FIRST_NAME]}
                        helperText={errors[FIRST_NAME]}
                        fullWidth
                        onChange={handleChange}
                        id={FIRST_NAME}
                        name={FIRST_NAME}
                        autoFocus={hasInviteCode}
                        onBlur={handleBlur}
                        onFocus={() => handleErrorField(FIRST_NAME, false)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel htmlFor={LAST_NAME}>Last Name *</InputLabel>
                    <TextField
                        margin="dense"
                        value={user[LAST_NAME]}
                        error={!!errors[LAST_NAME]}
                        helperText={errors[LAST_NAME]}
                        fullWidth
                        onChange={handleChange}
                        id={LAST_NAME}
                        name={LAST_NAME}
                        onBlur={handleBlur}
                        onFocus={() => handleErrorField(LAST_NAME, false)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInfo tootltipMsg="The e-mail you primarily use for communication with your employer.">
                        <InputLabel htmlFor={PRIMARY_EMAIL}>Primary Email Address * </InputLabel>
                    </TextInfo>
                    <TextField
                        margin="dense"
                        value={user[PRIMARY_EMAIL]}
                        error={!!errors[PRIMARY_EMAIL]}
                        helperText={errors[PRIMARY_EMAIL]}
                        fullWidth
                        disabled={isIndividualCode}
                        onChange={handleChange}
                        id={PRIMARY_EMAIL}
                        name={PRIMARY_EMAIL}
                        onBlur={handleBlur}
                        onFocus={() => handleErrorField(PRIMARY_EMAIL, false)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInfo tootltipMsg="To provide continuity in accessing your account.">
                        <InputLabel htmlFor={SECONDARY_EMAIL}>Secondary Email Address </InputLabel>
                    </TextInfo>
                    <TextField
                        margin="dense"
                        value={user[SECONDARY_EMAIL]}
                        error={!isWithoutSecondaryEmail && !!errors[SECONDARY_EMAIL]}
                        helperText={errors[SECONDARY_EMAIL]}
                        fullWidth
                        disabled={isWithoutSecondaryEmail}
                        onChange={handleChange}
                        id={SECONDARY_EMAIL}
                        name={SECONDARY_EMAIL}
                        onBlur={handleBlur}
                        onFocus={() => handleErrorField(SECONDARY_EMAIL, false)}
                    />
                    <Box display="flex" alignItems="flex-start">
                        <CustomCheckBox
                            isIntern
                            checked={isWithoutSecondaryEmail}
                            onChange={handleSecondaryEmailCheck}
                        />
                        <Typography variant="caption" align="left">
                            I do not have a secondary e-mail address.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor={PHONE}>Mobile Phone Number *</InputLabel>
                    <CustomInputPhone
                        showDropdown={false}
                        country={"us"}
                        onlyCountries={["us"]}
                        masks={{ us: "(...) ... - ...." }}
                        placeholder="(123) 456 - 7890"
                        value={user[PHONE]}
                        prefix=""
                        onKeyDown={(e) => e.key === "Enter" && onSubmit(e)}
                        enableAreaCodes={false}
                        disableCountryCode={true}
                        error={!!errors[PHONE]}
                        onChange={(newPhone) => {
                            handleChange({ target: { name: PHONE, value: newPhone } });
                        }}
                        onBlur={() => handleBlur({ target: { name: PHONE } })}
                        onFocus={() => handleErrorField(PHONE, false)}
                    />
                    {!!errors[PHONE] && (
                        <Typography variant="caption" color={theme.palette.error.main} marginX={theme.spacing(1.75)}>
                            {errors[PHONE]}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12} md={6} position="relative">
                    <InputLabel htmlFor={PASSWORD}>New Password *</InputLabel>
                    <TextField
                        margin="dense"
                        value={user[PASSWORD]}
                        error={!!errors[PASSWORD]}
                        helperText={errors[PASSWORD]}
                        fullWidth
                        id={PASSWORD}
                        name={PASSWORD}
                        type={getPswTypeField(showPassword)}
                        onChange={handleChange}
                        onFocus={() => {
                            setIsPswFocus(passwordFields.currentPsw);
                            handleErrorField(PASSWORD, false);
                        }}
                        onBlur={(e) => {
                            setIsPswFocus(passwordFields.nonePsw);
                            handleBlur({ target: { name: PASSWORD } });
                            handleBlur({ target: { name: CONFIRM_PASSWORD } });
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        onMouseDown={(e) => e.preventDefault()}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {isPswFocus === 1 && <Rules rules={rules} />}
                </Grid>
                <Grid item xs={12} md={6} position="relative">
                    <InputLabel htmlFor={CONFIRM_PASSWORD}>Confirm Password *</InputLabel>
                    <TextField
                        margin="dense"
                        value={user[CONFIRM_PASSWORD]}
                        error={!!errors[CONFIRM_PASSWORD]}
                        helperText={errors[CONFIRM_PASSWORD]}
                        fullWidth
                        id={CONFIRM_PASSWORD}
                        name={CONFIRM_PASSWORD}
                        type={getPswTypeField(showConfirmPassword)}
                        onChange={handleChange}
                        onFocus={() => {
                            setIsPswFocus(passwordFields.confirmationPsw);
                            handleErrorField(CONFIRM_PASSWORD, false);
                        }}
                        onBlur={() => {
                            setIsPswFocus(passwordFields.nonePsw);
                            handleBlur({ target: { name: CONFIRM_PASSWORD } });
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        onMouseDown={(e) => e.preventDefault()}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {isPswFocus === 2 && <Rules rules={rulesOther} />}
                </Grid>
            </Grid>
            <Grid container mt={3}>
                <Box display="flex" alignItems="flex-start">
                    <CustomCheckBox
                        checked={isUnderstanding}
                        onChange={handleCheck}
                        type={!isUnderstanding ? "error" : ""}
                    />
                    <Typography variant="body1" align="left">
                        I understand and have read the{" "}
                        <a href={URL_POLICY} target="_blank" rel="noopener noreferrer">
                            Privacy Policy
                        </a>
                        ,{" "}
                        <a href={URL_ADV} target="_blank" rel="noopener noreferrer">
                            ADV
                        </a>{" "}
                        and{" "}
                        <a href={URL_TyC} target="_blank" rel="noopener noreferrer">
                            Terms & Conditions
                        </a>{" "}
                        .*
                    </Typography>
                </Box>
            </Grid>
            <Grid container mt={3} spacing={2}>
                <Grid item xs={12}>
                    <Button fullWidth size="large" color="primary" variant="contained" type="submit">
                        Register
                    </Button>
                </Grid>
            </Grid>
        </form>
    );

    const renderBody = () => {
        if (success)
            return (
                <SuccessMessage
                    title={`Congratulations ${user.firstName}!`}
                    subtitle="Your account has been successfully created."
                    buttonText="Continue"
                    buttonAction={logInPopup}
                />
            );
        else
            return (
                <LayoutForm
                    title="Create your account"
                    secondText="Already have an account? "
                    actionText="Sign in"
                    actionLink="/login"
                >
                    {canContinue ? renderDirectForm() : renderInviteCodeForm()}
                </LayoutForm>
            );
    };

    return (
        <Layout>
            {openAlert && (
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={openAlert}
                    onClose={handleClose}
                >
                    <MuiAlert elevation={6} variant="filled" severity={alert.type} onClose={handleClose}>
                        {alert.msg}
                    </MuiAlert>
                </Snackbar>
            )}
            <BodyDiv>
                <BodyBox>{renderBody()}</BodyBox>
            </BodyDiv>
            <ImgBox />
        </Layout>
    );
};

export default Onboarding;
