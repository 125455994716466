import customTheme from "@/theme";
import React from "react";

export const regexLowerCase = /[a-z]/;
export const regexUpperCase = /[A-Z]/;
export const regexNumber = /[0-9]/;
export const regexNames = /^[a-zA-Z\s-]{2,75}$/;
export const regexEmail = /^[a-zA-Z0-9.+_-]{2,64}@[a-zA-Z0-9.+_-]+\.[a-zA-Z]{2,}$/;

export const CONTACT_EMAIL = "mysupport@onedigital.com";

export const ONBOARDING_FIELDS = {
    INVITE_CODE: "inviteCode",
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    SECONDARY_EMAIL: "secondaryEmail",
    PRIMARY_EMAIL: "primaryEmail",
    PHONE: "phone",
    PASSWORD: "password",
    CONFIRM_PASSWORD: "confirmPassword",
    IS_UNDERSTANDING: "isUnderstanding",
};

export const initUser = {
    firstName: "",
    lastName: "",
    secondaryEmail: "",
    primaryEmail: "",
    phone: "",
    password: "",
    confirmPassword: "",
};

export const initAlert = {
    type: "",
    msg: "",
};

export const initLoading = {
    show: false,
    msg: "",
};

export const statesRules = {
    normal: { name: "normal", color: customTheme.palette.statesRules.normal },
    done: { name: "done", color: customTheme.palette.statesRules.done },
    wrong: { name: "wrong", color: customTheme.palette.statesRules.wrong },
};

export const initRulesOther = [{ label: "Passwords must match", state: statesRules.normal }];

export const initRules = [
    { label: "Must contain at least one lowercase letter", state: statesRules.normal },
    { label: "Must contain at least one uppercase letter", state: statesRules.normal },
    { label: "Must contain one number", state: statesRules.normal },
    { label: "Must be at least 8 characters", state: statesRules.normal },
];

export const URL_POLICY = process.env.REACT_APP_URL_POLICY;
export const URL_ADV = process.env.REACT_APP_URL_ADV;
export const URL_TyC = process.env.REACT_APP_URL_TyC;

export const passwordFields = {
    nonePsw: 0,
    currentPsw: 1,
    confirmationPsw: 2,
};

export const errorMessages = {
    [ONBOARDING_FIELDS.INVITE_CODE]: "Please enter valid invite code",
    [ONBOARDING_FIELDS.FIRST_NAME]: "A minimum of 2 characters is required",
    [ONBOARDING_FIELDS.LAST_NAME]: "A minimum of 2 characters is required",
    [ONBOARDING_FIELDS.SECONDARY_EMAIL]: "Please enter a valid secondary email or check the box.",
    [ONBOARDING_FIELDS.PRIMARY_EMAIL]: "A properly formatted email address is required. Example: name@company.com",
    [ONBOARDING_FIELDS.PHONE]: "Please use 10 numbers",
    [ONBOARDING_FIELDS.PASSWORD]: "Create your password",
    [ONBOARDING_FIELDS.CONFIRM_PASSWORD]: "Confirm your password",
};
