import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { styled, useTheme } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import portalLogo from "@assets/portal-img.png";
import { staticLinkFinancialTips, statickLinkCalendly } from "@utils/constants";
import useUser from "@auth/useUser";
import useApi from "@auth/useApi";
import Carousel from "@components/carousel";
import { entitlements, ITEMS } from "./constants";
import Notification from "../../components/notification";
import {CONTACT_EMAIL} from "../Onboarding/constants";

const ColoredText = styled("p")(({ theme }) => ({
    ...theme.typography.h5,
    background: "linear-gradient(90deg, #0078A2 0%, #8EB935 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: "bolder",
    display: "inline",
}));

const Banner = styled("div")(({ theme }) => ({
    position: "relative",
    zIndex: "-1",
    backgroundImage: `url(${portalLogo})`,
    backgroundColor: theme.palette.extras.banner,
    backgroundBlendMode: "multiply",
    [theme.breakpoints.up("md")]: {
        height: "17.75rem",
    },
    "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        width: "100%",
        height: "0.5rem",
        background: theme.palette.extras.bgLinear,
    },
}));

const BannerContainer = styled(Container)(({ theme }) => ({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "white",
    paddingBottom: theme.spacing(13),
    paddingTop: theme.spacing(13),
    [theme.breakpoints.up("md")]: {
        paddingBottom: 0,
        paddingTop: 0,
    },
}));

const CardsBoxWrapper = styled(Container)(() => ({
    marginTop: "-4rem",
    marginBottom: "2rem",
}));

const QuoteBox = styled(Box)(({ theme }) => ({
    height: theme.spacing(25),
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.extras.spam,
    display: "flex",
}));

const CardElem = styled(Card, {
    shouldForwardProp: (prop) => !["bgColor"].includes(prop),
})(({ bgColor }) => ({
    backgroundColor: bgColor,
    height: "100%",
}));

const CardElemBtn = styled(Button, {
    shouldForwardProp: (prop) => !["color"].includes(prop),
})(({ color }) => ({
    color: color,
}));

const StyledImg = styled(Box)(() => ({
    objectPosition: "center",
    width: "100%",
    height: "auto",
}));

const CarouselContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
}));

const CarouselItem = styled(Button)(({ theme }) => ({
    color: "black",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
}));

const Item = ({ title, subtitle, actionText, onAction, bgColor, isLoading = false, color = "white" }) => {
    const theme = useTheme();
    return (
        <CardElem bgColor={bgColor}>
            <Box
                padding={theme.spacing(4)}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
            >
                <Box>
                    <Typography
                        gutterBottom
                        variant="h4"
                        component="div"
                        textAlign="left"
                        fontWeight="bold"
                        color={color}
                    >
                        {title}
                    </Typography>
                    <Typography variant="h6" textAlign="left" color={color}>
                        {subtitle}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="left" mt={6.5}>
                    <CardElemBtn
                        variant="text"
                        endIcon={<EastIcon />}
                        onClick={onAction}
                        color={color}
                        disabled={isLoading}
                    >
                        <Typography variant="h6" textAlign="left" color={color} className="underline">
                            {actionText}
                        </Typography>
                    </CardElemBtn>
                </Box>
            </Box>
        </CardElem>
    );
};

const Welcome = () => {
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const {name} = useUser();
    const axios = useApi();

    const openNewTab = (resp) => {
        const newTab = window.open("", "_blank");

        const form = newTab.document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", resp.data.assertionConsumerServiceURL);

        const field = newTab.document.createElement("input");
        field.setAttribute("type", "hidden");
        field.setAttribute("name", "SAMLResponse");
        field.setAttribute("value", resp.data.samlResponse);

        form.appendChild(field);

        newTab.document.body.appendChild(form);
        form.submit();
    };

    const onRedirect = (url) => {
        window.open(url, "_blank");
    };

    const mgpSSO = async (entitlement) => {
        try {
            setIsLoading(true);
            const resp = await axios.post(`/buildMgpSaml`, {
                entitlement: entitlement,
            });
            setIsNotificationOpen(false);
            openNewTab(resp);
        } catch {
            setIsNotificationOpen(true);
        } finally {
            setIsLoading(false);
        }
    };

    const renderCarouselItem = (item) => {
        return (
            <CarouselItem variant="text" key={item.title} onClick={() => mgpSSO(item.entitlement)}>
                <StyledImg component="img" src={item.thumb} alt={item.alt} />
                <Typography variant="h6" pt={theme.spacing(1)} textAlign="left">
                    {item.title}
                </Typography>
            </CarouselItem>
        );
    };

    return (
        <>
            <Notification
                open={isNotificationOpen}
                message="An error occurred while openning the link. Please try again"
                severity="error"
                onClose={() => setIsNotificationOpen(false)}
                autoHideDuration={4000}
            />
            <Box pt={theme.spacing(10)} display={"flex"} flexDirection={"column"} minHeight={"100%"}>
                <Box flex={1}>
                    <Banner>
                        <BannerContainer>
                            <Typography gutterBottom variant="h3" textAlign="left" fontWeight="bold" mb={0}>
                                Welcome back, {name}
                            </Typography>
                            <Typography variant="h6" textAlign="left">
                                Let’s power your financial potential, together.
                            </Typography>
                        </BannerContainer>
                    </Banner>
                    <CardsBoxWrapper>
                        <Grid container justifyContent="center" alignItems="stretch" spacing={3}>
                            <Grid item xs={12} md={4}>
                                <Item
                                    title="My Financial Plan"
                                    subtitle="Identify your goals and create a personalized plan to reach them."
                                    actionText="Launch"
                                    onAction={() => mgpSSO(entitlements.BLOCKS)}
                                    isLoading={isLoading}
                                    bgColor={theme.palette.blue.dark}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Item
                                    title="Financial Tips"
                                    subtitle="Find resources to help you manage your money more confidently."
                                    actionText="Take Charge"
                                    onAction={() => onRedirect(staticLinkFinancialTips)}
                                    bgColor={theme.palette.blue.main}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Item
                                    title="Connect with Someone"
                                    subtitle="Meet with an advisor for help with a financial question or big decision."
                                    actionText="Schedule Now"
                                    onAction={() => onRedirect(statickLinkCalendly)}
                                    bgColor={theme.palette.blue.other}
                                />
                            </Grid>
                        </Grid>
                    </CardsBoxWrapper>
                    <QuoteBox>
                        <Container>
                            <Typography variant="h5" textAlign="center">
                                Do your best work and <ColoredText>live your best life</ColoredText>
                            </Typography>
                        </Container>
                    </QuoteBox>
                    <CarouselContainer>
                        <Typography textAlign="center" variant="h4" fontWeight="bold">
                            Learning Library
                        </Typography>
                        <Typography textAlign="center" variant="h6" m={theme.spacing(1, 0, 2)}>
                            Check out these helpful decision-making tools and resources that power your financial
                            future.
                        </Typography>
                        <Box px={theme.spacing(0.75)}>
                            <Carousel infiniteLoop>{ITEMS.map(renderCarouselItem)}</Carousel>
                        </Box>
                    </CarouselContainer>
                </Box>
                <Box justifyContent="center" alignItems="center" padding="1rem" margin="2rem 0">
                    <Typography textAlign="center" variant="body2">
                        Investment advice is offered through OneDigital Investment Advisors, an SEC-registered
                        investment adviser and wholly owned subsidiary of OneDigital.
                    </Typography>
                    <Typography variant="body2" component="p" textAlign="center">
                        If you have any questions or need help, you can contact us {" "}
                        <a href={`mailto:${CONTACT_EMAIL}`} target="_blank" rel="noopener noreferrer">
                            <Typography variant="linkNotUnderline">
                                {" "}
                                <strong>here to get support</strong>{" "}
                            </Typography>
                        </a>
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default Welcome;
